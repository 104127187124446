@tailwind base;
@tailwind components;
@tailwind utilities;

.wallet-toggle::after {
  display: none !important;
}

.tippy-box[data-theme~='normal'] {
  background-color: #DBEAFE;
  color: #1E40AF;
}

.tippy-box[data-theme~='success'] {
  background-color: #D1FAE5;
  color: #065F46;
}

.tippy-box[data-theme~='fail'] {
  background-color: #FEE2E2;
  color: #991B1B;
}

.tippy-box[data-theme~='normal'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #DBEAFE;
}
.tippy-box[data-theme~='normal'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #DBEAFE;
}
.tippy-box[data-theme~='normal'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #DBEAFE;
}
.tippy-box[data-theme~='normal'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #DBEAFE;
}

.tippy-box[data-theme~='success'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #D1FAE5;
}

.tippy-box[data-theme~='success'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #D1FAE5;
}

.tippy-box[data-theme~='success'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #D1FAE5;
}

.tippy-box[data-theme~='success'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #D1FAE5;
}

.tippy-box[data-theme~='fail'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #FEE2E2;
}

.tippy-box[data-theme~='fail'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #FEE2E2;
}

.tippy-box[data-theme~='fail'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #FEE2E2;
}

.tippy-box[data-theme~='fail'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #FEE2E2;
}

